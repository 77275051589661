import * as React from "react"
import atelierJson from "../../pages/atelier.json"
import DefaultHead from "../components/layout/head";

// markup
const AtelierPage = ({pageContext}) => {
    const {lang,pageViewCount=0, isDesktop=false} = pageContext
    const firstLoadDelay = pageViewCount > 1 ? 0 : isDesktop ? 4.250 : 2.250
    const firstSpeedDelay = .5
    const {paragraphs=[]} = atelierJson[lang]

    return (
        <div className={"page-atelier"}>
            <div className="text">
                {paragraphs.map((p,k)=>{
                    const words = p.split(' ');
                    return <p key={k} className="text__part">{words.map((word,i)=>
                        <span key={i} style={{animationDelay:`${firstLoadDelay+k+(i/words.length)*firstSpeedDelay}s`}}>{/\n/.test(word) ? <br/>:' '}{word}</span>
                    )}</p>
                })}
            </div>
        </div>
    )
}

export default AtelierPage

export const Head = DefaultHead
